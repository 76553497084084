import "core-js/modules/es.string.replace.js";

/**
 * Global
 *
 * The main file, page logic which is needed on all pages will be included here.
 * Please note for specific components like a slider create a separated components/<component_name>.js
 * The components will be loaded through the component loader and are not needed on every page!
 */
import $ from 'jquery';
document.addEventListener('DOMContentLoaded', () => {
  const $menuToggle = $('.js-mobile-toggle');
  const $filterToggle = $('.js-filter-toggle');
  const $menuClose = $('.js-close-menu');
  const $widget = $('.widget');
  const $showLogin = $('.showlogin');
  $widget.find('.widget__title').addClass('is-active');
  $('.widget_layered_nav').each(function () {
    $(this).find('.chosen').parent().show().parent().find('p').addClass('is-active');
  });
  $('.widget__title', $widget).on('click', function (e) {
    e.preventDefault();
    $(this).next().slideToggle();
    $(this).toggleClass('is-active');
  });
  $('.js-final-button').click(function () {
    $('#place_order').trigger('click');
  });
  $filterToggle.on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('is-filter-open');
    e.stopPropagation();
  });
  $('.sidebar').on('click', function (e) {
    e.stopPropagation();
  });
  $(document).on('click', function (e) {
    if ($(e.target).is('.sidebar') === false) {
      $('body').removeClass('is-filter-open');
    }
  });
  $menuToggle.on('click', function (e) {
    e.preventDefault();
    $('html').toggleClass('is-open-menu');
  });
  $menuClose.on('click', function (e) {
    e.preventDefault();
    $('html').removeClass('is-open-menu');
  });
  $('.navigation, .header').on('click', function (e) {
    e.stopPropagation();
  });
  $(document).on('click', function (e) {
    if ($(e.target).is('.navigation, .header') === false) {
      $('html').removeClass('is-open-menu');
    }
  });
  $('.header__menu > ul').clone().appendTo('.navigation__main');
  $('.navigation__main > ul').parent('li').addClass('has-children');
  $('.navigation__main > ul > li.has_children > a').append('<span></span>');
  $('.navigation__main > ul > li.has_children > ul > li.has_children > a').append('<span></span>');
  $('.navigation__main > ul > li > a > span').click(function (e) {
    e.preventDefault();
    let $this = $(this).parent().next('ul');
    $('.navigation__main > ul > li.has_children > ul').not($this).slideUp();

    if (!$(this).parent().parent().hasClass('is-active')) {
      $('.navigation__main > ul > li.has_children').removeClass('is-active');
      $(this).parent().parent().addClass('is-active');
      $(this).parent().next('ul').slideDown();
    } else {
      $(this).parent().parent().removeClass('is-active');
      $(this).parent().next('ul').slideUp();
    }
  });
  $('.navigation__main > ul > li > ul > li > a > span').click(function (e) {
    e.preventDefault();
    let $this = $(this).parent().next('ul');
    $('.navigation__main > ul > li.has_children > ul > li.has_children > ul').not($this).slideUp();

    if (!$(this).parent().parent().hasClass('is-active')) {
      $('.navigation__main > ul > li.has_children > ul > li.has_children').removeClass('is-active');
      $(this).parent().parent().addClass('is-active');
      $(this).parent().next('ul').slideDown();
    } else {
      $(this).parent().parent().removeClass('is-active');
      $(this).parent().next('ul').slideUp();
    }
  });
  $('.widget_product_categories > ul > li.cat-parent > a').prepend('<span></span>');
  $('.widget_product_categories > ul > li.cat-parent > ul > li.cat-parent > a').prepend('<span></span>');
  $('.widget_product_categories > ul > li > a > span').click(function (e) {
    e.preventDefault();
    let $this = $(this).parent().next('ul');
    $('.widget_product_categories > ul > li.cat-parent > ul').not($this).slideUp();

    if (!$(this).parent().parent().hasClass('is-active')) {
      $('.widget_product_categories > ul > li.cat-parent').removeClass('is-active');
      $(this).parent().parent().addClass('is-active');
      $(this).parent().next('ul').slideDown();
    } else {
      $(this).parent().parent().removeClass('is-active');
      $(this).parent().next('ul').slideUp();
    }
  });
  $('.widget_product_categories > ul > li > ul > li > a > span').click(function (e) {
    e.preventDefault();
    let $this = $(this).parent().next('ul');
    $('.widget_product_categories > ul > li.cat-parent > ul > li.cat-parent > ul').not($this).slideUp();

    if (!$(this).parent().parent().hasClass('is-active')) {
      $('.widget_product_categories > ul > li.cat-parent > ul > li.cat-parent').removeClass('is-active');
      $(this).parent().parent().addClass('is-active');
      $(this).parent().next('ul').slideDown();
    } else {
      $(this).parent().parent().removeClass('is-active');
      $(this).parent().next('ul').slideUp();
    }
  }); // Hide Header on scroll down

  var c,
      currentScrollTop = 0,
      navbar = $('.header');
  $(window).scroll(function () {
    var a = $(window).scrollTop();
    var b = navbar.height();
    currentScrollTop = a;

    if (c < currentScrollTop && a > b + b) {
      navbar.addClass('header--fixed');
    } else if (c > currentScrollTop && !(a <= b)) {
      navbar.removeClass('header--fixed');
    }

    c = currentScrollTop;
  });
  $showLogin.on('click', function (event) {
    event.preventDefault();
    $(this).fadeOut();
  });
  $('.woocommerce div.product div.summary .price').html(function (i, html) {
    return html.replace(/&nbsp;/g, '');
  });
});